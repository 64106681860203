<template>
  <b-modal
    id="form-homepage"
    ref="form-homepage"
    dialog-class="global-form-dialog"
    content-class="global-form-content"
    header-class="global-form-header"
    body-class="global-form-body"
    hide-footer
    centered
    scrollable
    v-model="isShowEvaluation"
    :no-close-on-backdrop="true"
    title="请如实选择回答以下自评问题："
  >
    <template #modal-header="{ close }">
      <b-row style="width: 100%">
        <b-col><span>请如实选择回答以下自评问题：</span></b-col>
        <b-col style="display: flex; justify-content: flex-end" sm="2">
          <div @click="modelEssay" class="modelEssay">
            <span>{{ isShowTable ? "隐藏范文" : "查看范文" }}</span>
            <span :class="buttonIcon()"></span>
          </div>
        </b-col>
        <b-col sm="1" class="close-class">
          <b-button size="lg" @click="close()"> × </b-button>
        </b-col>
      </b-row>
    </template>
    <div>
      <div v-for="(result, index) in engSelfQuestions" :key="index">
        <div class="form-test">
          {{ result.questionNumber + ".  " + result.questionStatement }}
        </div>
        <div class="options">
            <b-form-radio-group
              v-model="selectes[index]"
              @change="
                (event) => {
                  changeArticle(event, index);
                }
              "
            >
              <div v-for="(option, index) in result.options" :key="index">
                <div v-if="option.statement">
                  <b-form-radio
                    v-show="optionValue"
                    :value="option.value"
                    v-if="
                      option.value == null
                        ? optionValue == false
                        : (optionValue = true)
                    "
                  >
                    <span v-if="option.statement">{{
                      option.value + ".  " + option.statement
                    }}</span>
                  </b-form-radio>
                </div>
              </div>
            </b-form-radio-group>
      
        </div>
      </div>
      <div v-show="isShowTable" class="modellaufsatz-class">
        <div class="modellaufsatz-text">范文</div>
        <div v-html="resultModelEssay" class="modellaufsatz-html"></div>
      </div>
      <div class="form-bottom">
        <div>
          <span class="iconfont icon-duihao2 yellow"></span>
          自评得分：
          <span class="yellow-test yellow">{{ totalPrice }}</span>
        </div>
        <div class="submit-rating" @click="submitRating">
          <span>提交自评</span>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      selectes: ["", "", "", "", "", "", "", "", "", ""],
    };
  },
  props: {
    totalPrice: {
      default: "",
    },
    engSelfQuestions: {
      default: [],
    },
    isShowTable: {
      default: false,
    },
    isShowEvaluation: {
      default: false,
    },
    resultModelEssay: {
      default: "",
    },
  },
  methods: {
    submitRating() {
      this.$emit("submitRating");
    },
    modelEssay() {
      this.$emit("modelEssay");
    },
    changeArticle(event, index) {
      this.$emit("changeArticle", event, index);
    },
    buttonIcon() {
      if (this.isShowTable) {
        return "iconfont icon-jiantou11";
      } else {
        return "iconfont icon-jiantou1";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-homepage {
  padding-left: 0;
}
.modelEssay {
  display: flex;
  align-items: baseline;
  cursor: pointer;
  & > span:nth-child(1) {
    font-size: 1rem !important;
    text-decoration: underline;
  }
  .icon-jiantou1 {
    font-size: 1.2rem;
    padding: 0rem 0.3rem;
  }
  .icon-jiantou11 {
    font-size: 1rem;
    padding: 0rem 0.3rem;
  }
}
</style>
<style lang="scss">
.global-form-dialog {
  max-width: 1200px;
  .modal-title {
    font-size: 1rem !important;
    font-weight: bold;
  }
}
.global-form-body {
  text-align: left;
  .form-test {
    padding: 1rem 0;
    color: #2cb774;
    font-weight: bold;
  }
  .options {
    border-bottom: 1px solid #f0f0f0;
  }
  .form-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
    position: relative;
    & > div:nth-child(1) {
      position: absolute;
      left: 0;
      .yellow {
        color: #ffc13b;
      }
      .yellow-test {
        font-size: 2rem;
      }
    }
    & > div:nth-child(2) {
      text-align: center;
      color: #fff;
      background-color: #2cb774;
      padding: 0.5rem 1.5rem;
      border-radius: 5px;
      font-size: 1.2rem;
      cursor: pointer;
      span {
      }
    }
  }
  .modellaufsatz-class {
    .modellaufsatz-text {
      padding: 1rem 0;
      font-weight: bold;
    }
    .modellaufsatz-html {
      line-height: 2;
    }
  }
}
.global-form-header {
  padding: 1rem 0rem 1rem 1rem;
  .btn-secondary {
    color: #6c757d;
    border-color: #fff;
    background-color: #fff;
    font-size: 2rem;
    line-height: 0;
  }
  .close-class {
    padding-right: 0;
    text-align: right;
  }
}
</style>
