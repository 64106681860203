<template>
  <b-container>
    <div id="wirte-answer">
      <div id="wirte-answer-title">
        <div>答题：</div>
        <div id="tabs">
          <div :class="'selected'">拍照答题</div>
          <!-- <div id="dividing-line"></div>
        <div :class="state ? 'selected' : 'no-selected'" @click="changeState">
          在线答题
        </div> -->
        </div>
      </div>
      <!-- <div v-show="state">
      <div>
        <tinymce></tinymce>
      </div>
    </div> -->
      <div>
        <div v-if="!finished" class="picture-answer">
          <b-row align-v="center">
            <b-col>
              <div id="answer-img-borde" @click="refreshImg">
                <div>上传完毕后，</div>
                <div>点此处刷新图片</div>
              </div>
            </b-col>
            <b-col>
              <div>
                <div class="code">
                  <div class="code-image code-border1"></div>
                  <div class="code-image code-border2"></div>
                  <div class="code-image code-border3"></div>
                  <div class="code-image code-border4"></div>
                  <div>
                    <vueQr
                      id="qr-code"
                      :text="baseurl + qdUrl"
                      logoSrc=""
                    ></vueQr>
                  </div>
                </div>
                <div class="padPictures">
                  <b-button @click="padPictures">Pad可点击此处拍照</b-button>
                </div>
              </div>
            </b-col>
            <b-col>
              <div style="text-align: left">
                <div style="font-size: 24px; font-weight: bold; color: #666666">
                  拍照答题
                </div>
                <div style="font-size: 16px; color: #666666">
                  先在纸上答题，答题完成后，使用微信“扫一扫”左侧二维码，拍照上传并自评得分
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-if="finished" class="picture-answer">
          <b-row>
            <b-col>
              <div class="answer-img">
                <b-img :src="baseurl + answerPath" />
              </div>
            </b-col>
            <b-col>
              <div class="re-upload">
                <b-button v-if="isLock" @click="restSubmit">重新上传</b-button>
              </div>
            </b-col>
            <b-col>
              <div style="text-align: left">
                <div style="font-size: 24px; font-weight: bold; color: #666666">
                  答案已上传
                </div>
                <div style="font-size: 16px; color: #666666">
                  如果需要修改拍照答案，请点击重新上传按钮，可以从新拍照上传
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
// import tinymce from "@/components/tinymce";
import { serveBaseURL } from "@/utils/request";
import { getStudentEnglishAnswerPicture } from "@/api/english-mock";
import vueQr from "vue-qr";
import { mapState } from "vuex";
const baseurl = serveBaseURL;
export default {
  name: "WirteAnswer",
  components: {
    vueQr,
  },
  props: {
    sectionId: {
      default: "",
    },
    questionId: {
      default: "",
    },
    studentExamId: {
      default: "",
    },
    isLock: {
      default: true,
    },
    examId: {
      default: "",
    },
  },
  data() {
    return {
      finished: false,
      baseurl,
      logoSrc: "",
      qdUrl: "",
      answerPath: "",
      refreshImgState: false,
    };
  },
  computed: {
    ...mapState(["userInfo", "examId", "addressImg"]),
  },
  watch: {
    sectionId: {
      handler(val) {
        console.log("监听图片地址", val);
        this.qdUrl = `/questionbank/mobile/uploadanswerimage/${
          this.questionId
        }?moduleprogressid=${this.sectionId}&studentid=${
          this.userInfo.studentId
        }&mode=english&examId=${this.$route.query.examId || ""}&studentExamId=${
          this.studentExamId || this.examId
        }`;
      },
      immediate: true,
    },
    isLock: {
      handler(val) {
        this.finished = !val;
        if (!val) {
          this.refreshImg();
        }
      },
      immediate: true,
    },
  },
  methods: {
    refreshImg() {
      if (sessionStorage.getItem("addressImg")) {
        return this.dataAssignment(sessionStorage.getItem("addressImg"));
      }
      const parmeter = {
        qid: this.questionId,
        studentExamId: this.studentExamId || this.examId,
      };
      getStudentEnglishAnswerPicture(parmeter).then((res) => {
        this.dataAssignment(res.data.result);
        sessionStorage.setItem("addressImg", res.data.result);
      });
    },
    dataAssignment(res) {
      console.log("res", res);
      if (res.indexOf("image:") > -1) {
        const imgs = res.replace("image:", "");
        const urlList = imgs.split(";");
        console.log("新图片地址", urlList);
        this.answerPath = "/api/media/getanswerimage/" + urlList[0];
        this.refreshImgState = true;
        this.finished = true;
        this.$emit("afterRefreshImg");
      } else {
        console.log("不是图片");
      }
    },
    padPictures() {
      window.open(baseurl + this.qdUrl, "_blank");
    },
    restSubmit() {
      this.finished = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 75px;
}
.code-image {
  position: absolute;
  width: 35px;
  height: 35px;
}
.code-border1 {
  position: absolute;
  top: 0;
  left: 0;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
.code-border2 {
  position: absolute;
  top: 0;
  right: 0;
  border-top: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.code-border3 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
.code-border4 {
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.selected {
  font-size: 16px;
  cursor: pointer;
}
.no-selected {
  font-size: 16px;
  color: #e5e5e5;
  cursor: pointer;
}
#dividing-line {
  height: 20px;
  width: 2px;
  background-color: #dcdcdc;
  margin: 0 22px;
}
#wirte-answer-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
}
#tabs {
  display: flex;
  align-items: center;
}
#answer-img-borde {
  width: 225px;
  height: 250px;
  border: 2px dashed #c9c9c9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}
.answer-img {
  width: 200px;
  height: 300px;
  img {
    max-height: 340px;
    max-width: 225px;
  }
}
.re-upload {
  // padding: 0 10rem;
}
// #qr-code {
//   width: 130px;
//   height: 150px;
//   background-color: white;
//   border-radius: 10px;
//   // box-shadow: 2px 2px 10px #909090;
// }
.picture-answer {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  border-top: 1px solid #dcdcdc;
  padding-top: 50px;
}
</style>
<style lang="scss">
.answer-img {
  .el-image {
    width: 100%;
    height: 100%;
  }
  .el-image-viewer__close {
    top: 110px;
    color: #fff;
  }
}
.padPictures {
  text-align: center;
  button {
    background-color: #f0f0f0;
    font-weight: bold;
    color: #000000;
    border: none;
  }
}
</style>
